.subscribers{
  display: flex;
  box-sizing: border-box;
  padding: 30px 30px;
  align-items:center;
  width: 100%;
  gap: 50px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  justify-content: center;
  flex-wrap: wrap;
}

.subscribers__info{
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.subscribers__info-container{
  max-width: 100px;
  display: flex;
  flex-direction: column;
  align-items:center;
  gap: 5px;
}

.subscribers__info-number{
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}


.subscribers__info-text{
  font-size: 11px;
  text-align: center;
}
