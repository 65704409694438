@font-face {
  src: url('./Inter-Regular/Inter-Regular.woff2') format('woff2'),
       url('./Inter-Regular/Inter-Regular.woff') format('woff2');
  font-family: 'Inter';
  font-weight: 400;
}

@font-face {
  src: url('./Inter-Medium/Inter-Medium.woff2') format('woff2'),
       url('./Inter-Medium/Inter-Medium.woff') format('woff2');
  font-family: 'Inter';
  font-weight: 500;
}
