.invoice {
  width: 720px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #25292d;
}

.invoice__header {
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
}

.invoice__user-name {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.invoice__user-email {
  margin: 5px 0 0;
  padding: 0;
  opacity: 0.7;
}

.invoice__amount-due-container {
}

.invoice__amount-due {
  margin: 0;
  padding: 0;
}

.invoice__amount-total {
  display: flex;
  justify-content: end;
  margin: 5px 0 0;
  padding: 0;
  opacity: 0.7;
}

.invoice__billed {
  display: flex;
  justify-content: space-between;
  padding: 25px;
}

.invoice__billed-info {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.invoice__title {
  font-size: 15px;
  font-weight: 600;
}

.invoice__login {
  font-size: 15px;
}

.invoice__email {
  font-size: 15px;
}

.invoice__details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.invoice__details-item {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.invoice__number {
  font-size: 15px;
}

.invoice__date {
  font-size: 15px;
}

.invoice__categories {
  padding: 25px;
  display: flex;
  flex-direction: column;
}

.invoice__category-header {
  display: flex;
  justify-content: space-between;
  padding: 0 0 3px;
}

.invoice__period {
  padding: 0 0 8px;
  border-bottom: solid 1px #dee2e6;
  font-size: 13px;
}

.invoice__category {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  border-bottom: solid 1px #dee2e6;
  padding: 8px 0;
}

.invoice__summary {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.invoice__subtotal {
  display: flex;
  width: 200px;
  justify-content: space-between;
  border-bottom: solid 1px #dee2e6;
  padding: 8px 0;
}
