.user-popup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.user-popup__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.user-popup__category-active {
  /* Ваши стили для активной категории */
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.user-popup__category-inactive {
  /* Ваши стили для неактивной категории */
  background-color: #f8f9fa;
  color: #6c757d;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.user-popup__category-inactive:hover {
  background-color: #e9ecef;
}

.user-card__access {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.user-popup__access-active,
.user-popup__access-inactive {
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.user-popup__access-active {
  background-color: #007bff;
  color: white;
}

.user-popup__access-inactive {
  background-color: #f8f9fa;
  color: #6c757d;
}

.user-popup__access-inactive:hover {
  background-color: #e9ecef;
}
