.button-card {
    min-width: 100px;
    height: 50;
    border-radius: 6px;
    background-color: white;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
    transition: all 0.5s ease-in-out;
    display: flex;
    align-items: center;
  }

  .button-card:hover{
    cursor: pointer;
    transform: scale(1.1, 1.1);
  }

  .button-card__main {
    width: 100%;
    padding: 16px 16px 16px;
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  .button-card__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .button-card__category {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
  }

  .button-card__item {
    font-size: 12px;
  }

  .button-card__time {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
  }

  .button-card__subscription_end{
    display: flex;
    flex-direction: row;
    column-gap: 7px;
    align-items: center;
  }

  .button-card__title {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    max-width: 290px;
  }

  .button-card__status{
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .button-card__status_active{
    background-color: #4CAF50;
  }

  .button-card__status_disable{
    background-color: grey;
  }

  .button-card-wrapper {
    position: relative;
    display: inline-block; /* или другое свойство display, в зависимости от вашего дизайна */
  }

  .button-card__delete {
    position: absolute;
    top: -10px; /* Вы можете регулировать это значение, чтобы добиться нужного вам положения */
    right: -10px; /* Аналогично, регулировать по вашему усмотрению */
    padding: 5px;
    cursor: pointer;
    color: black;
    border-radius: 50%;
  }
