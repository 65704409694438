.users-card {
  box-sizing: border-box;
  padding: 30px 0px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.users-card__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px 30px;
  align-items: center;
  justify-content: center;
}

.filter-buttons {
  display: flex;
  gap: 10px; /* Отступ между кнопками */
}

.filter-buttons__button {
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

.filter-buttons__button_active {
  color: white;
  background-color: #007bff;
}

.filter-buttons__button:hover {
  background-color: #eee; /* Цвет фона при наведении */
}

.filter-buttons__button_active:hover {
  background-color: #007bff; /* Цвет фона активной кнопки при наведении будет таким же, как без наведения */
}

.users-card__search {
  box-sizing: border-box;
  width: 50%;
}
