.buttons-card {
  box-sizing: border-box;
  padding: 30px 0px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.buttons-nav {
  display: flex;
  gap: 15px;
}

.buttons-link {
  text-decoration: none;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
  cursor: pointer;
  background-color: #f8f9fa;
}

.buttons-link:hover {
  background-color: #eee;
}

.buttons-link.active {
  color: white;
  background-color: #007bff;
}

.buttons-card__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px 30px;
  align-items: center;
  align-content: center;
}

.add-button {
  cursor: pointer;
  min-width: 75px;
  height: 75px;
  background-color: white;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 500;
  font-size: 25px;
}
