.infotooltip {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}

.infotooltip__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 430px;
  padding: 34px 36px 37px;
  gap: 25px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 25px rgb(0 0 0 / 15%);
  cursor: default;
}

.infotooltip_opened {
  cursor: pointer;
  opacity: 1;
  visibility: visible;
  transition: visibility 0s, opacity 0.5s ease-in-out;
}

.infotooltip__close-button {
  position: absolute;
  right: -40px;
  top: -40px;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  background-color: transparent;
  background-image: url(../../images/close-button.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s ease-in-out;
}

.infotooltip__close-button:hover {
  cursor: pointer;
  transform: scale(0.95);
}

.infotooltip__image {
  max-width: 150px;
  max-height: 150px;
  margin: 0;
  padding: 0;
}

.infotooltip__title {
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
  cursor: text;
}

@media screen and (max-width: 500px) {
  .infotooltip__close-button {
    right: 0;
    top: -38px;
    width: 20px;
    height: 20px;
  }

  .infotooltip__container {
    max-width: 282px;
    padding: 25px 22px;
  }
}
