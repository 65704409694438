.nav-container {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
}

.nav-link {
  text-decoration: none;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.nav-link:hover {
  background-color: #eee;
}

.nav-link.active {
  color: white;
  background-color: #007bff;
}
