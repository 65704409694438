.app {
    min-height: 100vh;
    min-width: 320px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    font-family: 'Inter', Arial, sans-serif;
    color: #000;
    scrollbar-gutter: stable;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    background-color: #ebebeb;
  }
